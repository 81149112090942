import { useEffect, useState } from "react"


/**
 * Hook to use, when the component is mounted or not mounted.
 */
const useMounted = (): boolean => {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
    return () => setMounted(false)
  }, [])

  return mounted
}

/**
 * Hook to use, when the page should jump to a specific component/section of the page, which
 * is not immediately rendered, b/c e.g. a certain entity has to be loaded first.
 * The hashId will be extracted from the url.
 *
 * @param scrollBehavior behavior of the scrolling
 */
export const useScrollToHash = (scrollBehavior: ScrollBehavior = 'smooth'): void => {
  const location = useWindowLocation()
  useEffect(() => {
    // Scroll to the element with the ID from the fragment identifier
    if (location?.hash) {
      const element = document.querySelector(location.hash)
      if (element) {
        element.scrollIntoView({ behavior: scrollBehavior })
      }
    }
  }, [location?.hash])
}

/**
 * Listening to the location (url of the current visited page) => e.g. can be directly used in useEffect
 *
 * Should be used, when the current url is needed.
 */
export const useWindowLocation = (): Location => {
  const mounted = useMounted()
  const [location, setLocation] = useState<Location>(mounted ? window.location : undefined)

  useEffect(() => {
    // the window is defined and available after the component is mounted
    if (!mounted) return

    const setWindowLocation = () => {
      setLocation(window.location)
    }

    if (!location) {
      setWindowLocation()
    }

    // if the window changes, set the location
    window.addEventListener('windowLocationState', setWindowLocation)

    // when the component is unmounting, remove the listener
    return () => {
      window.removeEventListener('windowLocationState', setWindowLocation)
    }
  }, [mounted, location])

  return location
}

export interface WindowSize {
  width: number
  height: number
}

/**
 * Hook to use the size of the current window even while resizing
 * e.g. to adapt the size of embedded elements by resizing them
 *
 * usage:
 *
 * const windowSize = useWindowSize()
 * const width = windowSize.width
 */
export const useWindowSize = (): WindowSize => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  })
  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    // Add event listener
    window.addEventListener("resize", handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return windowSize
}