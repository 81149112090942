// #region usecases and actions

import { Action } from "redux-saga"

import { IUserObjectRole, IUserProjectRole, IRI } from "@api/schema"
import { IFormikActions } from "@basics/form"
import { ILoadCollectionByAction, newLoadCollectionAction } from "@redux/helper/actions"
import { EntityType } from "@redux/reduxTypes"
import { usecaseKeyForLoadCollection } from "@services/hooks/useEntityCollection"

/**
 * Defines all usecases for a UserObjectRole.
 *
 * NOTE: To change the content of an existing project membership by the member himself ("Update")
 * the update-endpoint is used, while for changing the role ("Change role") may only be triggered by the
 * project coordinator via the change-role-endpoint.
 * So it may happen, that the client has to do 2 calls, if a project coordinator changes his role
 * and his motivation/skills.
 */
export enum UserObjectRoleUsecase {
  /** Delete an user object role. */
  Delete = "_usecase_delete_user_object_role",
  /** Update metadata, especially motivation and skills, of an UserProjectRole. */
  Update = "_usecase_update_uor_meta_data",
  /**
   * Change member role of an UserProjectRole.
   * Only a project coordinator can change the role.
   */
  ChangeRole = "_usecase_update_project_membership_role",
}

/**
 * Usecase key for a single update or delete action for a UserObjectRole.
 * We do not use the function usecaseKeyForUpdateModel or usecaseKeyForDeleteModel,
 * b/c the user object roles have an own saga, which needs to be triggered by user object roles redux action using
 * an unique usecaseKey.
 */
export const usecaseKeyForUORAction = (usecase: UserObjectRoleUsecase, userObjectRole: IUserObjectRole): string =>
  usecase + userObjectRole?.["@id"]

export interface IUserObjectRoleAction extends Action<UserObjectRoleUsecase> {
  actions: IFormikActions
  userObjectRole: IUserObjectRole
}

export interface IUserProjectRoleUpdateAction extends IUserObjectRoleAction {
  userObjectRole: IUserProjectRole
  actions: IFormikActions
}

export const deleteUserObjectRoleAction = (
  userObjectRole: IUserObjectRole,
  actions: IFormikActions,
): IUserObjectRoleAction => ({
  actions,
  userObjectRole,
  type: UserObjectRoleUsecase.Delete
})

export const updateUserProjectRoleAction = (
  userProjectRole: IUserProjectRole,
  actions: IFormikActions,
): IUserProjectRoleUpdateAction => ({
  actions,
  userObjectRole: userProjectRole,
  type: UserObjectRoleUsecase.Update
})

export const changeRoleOfUserProjectRoleAction = (
  userProjectRole: IUserProjectRole,
  actions: IFormikActions,
): IUserProjectRoleUpdateAction => ({
  actions,
  userObjectRole: userProjectRole,
  type: UserObjectRoleUsecase.ChangeRole
})

/**
 * Load all user object roles of a given entity as an Iri, e.g. a user or a project.
 * Used to refresh/reload user object roles after an update.
 *
 * NOTE: If filtering of user object roles is possible, and one usecase is updated,
 * other related usecases with user object roles have to refreshed too.
 * Means, if filtering of user object roles is possible, the reloading/refreshing of
 * user object roles have to be refactored.
 * @see https://futureprojects.atlassian.net/browse/FCP-1074
 */
export const loadUORsAction = (objectIri: IRI): ILoadCollectionByAction => newLoadCollectionAction(
  EntityType.UserObjectRole,
  null,
  usecaseKeyForLoadCollection(null, null, objectIri),
  objectIri,
  true /* loadAll */
)

// #endregion